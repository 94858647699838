* {
  width: 100%;
  box-sizing: border-box;
}

.wrapper {
  padding: 2% 5% 0% 5%;
  margin: 0 auto;
}
#mainloader{display:grid;
  width:auto;
  justify-content: center;
  align-self: center;
  padding-top: 250px;
  height:100%;
  }
.header {
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: auto;
  gap: 10px;
  background-color: rgb(241, 237, 238);
  padding: 5px 30px 5px 30px;
  margin-top: 1%;
  border: 1px solid #ccc;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
  align-content: center;
}

.logo {
  align-self: center;
  height: 56px;
  width: 112px;
}

.bar {
  align-self: center;
  width: 2px;
  height: 20px;
  background-color: black;
}

.titel {
  font-weight: bolder;
  font-size: larger;
}

.subheader {
  display: grid;
  grid-template-columns: auto 1fr;
  height: auto;
  gap: 10px;
  padding: 10px 10px 10px 30px;
  background-color: rgb(0, 0, 0);
  margin-top: 2%;
  border: 1px solid #ccc;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
  align-content: center;
}

.logo2 {
  align-self: center;
  height: 40px;
  width: 40px;
}

.titel2 {
  color: white;
  font-size: medium;
  font-weight: bold;
}

.tab-container {
  height: auto;
  margin-top: 20px;
}

.tabs {
  display: grid;
  margin-top: 20px;
  gap: 20px;
  list-style-type: none;
  grid-template-columns: auto auto 1fr;
  padding-left: 0;
  height: auto;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.tabs li {
  justify-content: start;
  align-self: baseline;
  padding-bottom: 10px;
  height: auto;
  font-size: small;
}

.tabs li.active {
  color: #ed0202;
  border-bottom: 2px solid rgb(221, 0, 0);
}

.tab-content {
  margin-top: 20px;
}

.tab-content {
  height: auto;
}