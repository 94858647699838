* {
  height: auto;
  width: 100%;
  box-sizing: border-box;
}

.vehicle-wrapper {
  height: auto;
}

.q-section {
  height: auto;
  display: grid;
  gap: 50px;
  font-size: small;
  grid-template-columns: 1fr 1fr;
}

.dropdown {
  background-color: rgb(242, 242, 242);
  padding: 10px;
  border: 0px;
  border-radius: 5px;
  font-size: small;
  margin: 10px 0px 20px 0px;
}

.dropdown:focus {
  border: 1px solid red;
  outline: none;
}

option {
  background-color: white;
}

.query-section:focus {
  border: 1px solid red;
  outline: none
}

.query-section {
  align-content: start;
  border: 0px;
  margin-top: 10px;
  height: 100px;
  border-radius: 5px;
  padding: 20px;
  background-color: rgb(242, 242, 242);
}

.chatbox {
  margin-top: 10px;
  border: 0px;
  height: 190px;
  background-color: rgb(242, 242, 242);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  padding: 20px;
}

.question {
  background-color: rgb(0, 0, 0);
  border: 1.5px solid red;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  padding: 5px;
  margin: 5px;
}

.answer {
  border: 1.5px solid #07da00;
  border-radius: 5px;
  height: auto;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 5px;
  margin: 5px;
}

.submit-button {
  background-color: black;
  width: 80px;
  height: 35px;
  margin-top: 20px;
  border-radius: 5px;
  color: #00ff00;
  margin-bottom: 30px;
}

.submit-button:hover {
  border: 1px solid rgb(255, 0, 0);
}
#vehicleloader{display:grid;
              width:auto;
              justify-content: center;
              align-self: center;
              margin-top: -20px;}
.dataContainer {
  display: grid;
  gap: 50px;
  grid-template-columns: auto 1fr;
}

#tableContainer {
  max-height: 450px;
  overflow: auto;
  max-width: 550px;
}

.table {
  border-collapse: collapse;
}

.table td,
th {
  color:black;
  border: 1px solid black;
  text-align: left;
}

#tabletotextContainer {
  width: 50%;
}
#tableToText{width:50%;}
.plotContainer {
  width: auto;
  justify-self: end;
  justify-content: end;
}

#answerContainer {
  margin-top: 30px;
  display: flex;
  gap: 50px;
  grid-template-columns: 1fr,auto;
 
}

.answerbox {
  height: 150px;
  border: 0px;
  padding: 20px;
  width: 50%;
  overflow-y: auto;
  background-color: rgb(242, 242, 242);
  border-radius: 5px;
}

.neograph_collapse{height:350px;
                    overflow-y:auto;
                    border:1px solid rgb(58, 58, 58); 
                    position :relative;
                    padding:0px;
                    margin:0px;}
.neograph_expand{height:500px;
                 overflow-y:auto;
                 position:fixed;
                 top: 50%;
                 left:50%;
                 width:80vw;
                 z-index: 1000;
                 transform: translate(-50%,-50%);
                 
                }                    
#expand{
        width:20px;
        position:absolute;
        top:10px;
        left:10px;
        padding:0px;
        z-index: 1;}      
 #expand:hover{height:25px;
               width:25px;}                     
.query-section:focus {
  border: 1px solid red;
  outline: none
}